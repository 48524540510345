import React, { useState, useEffect } from 'react';
import MyBook from './Book';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
   background-color: rgba(50,50,50);
  }
`;

const BookContainer = styled.div`
  ${'' /* background-color: rgba(90, 90, 90); */}
  width: ${(props) => props.width};
  max-height: 100vh;
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  console.log({ width, height });
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const { height, width } = useWindowDimensions();
  const [dims, setDims] = useState({ height: height, width: width });

  useEffect(() => {
    if (width > height) {
      setDims({ height: height, width: (height * 11 * 1.19) / 8.5 });
    } else {
      setDims({ height: (width * 8.5) / 11, width: width });
    }
  }, [height, width]);

  console.log(`h: ${height} w: ${width}`);
  console.log(`calc w: ${(height * 11) / 8.5}`);

  return (
    <>
      <GlobalStyle />
      <BookContainer width={`${dims.width}px`}>
        <MyBook key={width} />
      </BookContainer>
    </>
  );
}

export default App;
